import React from 'react'
import '../styles/home.css';
import { Container , Row, Col,UncontrolledCarousel } from 'reactstrap';
/*import heroImg from '../assets/images/hero-img01.jpg'
import heroImg02 from '../assets/images/hero-img02.jpg'
import heroVideo from '../assets/images/hero-video.mp4'

import worldImg from '../assets/images/world.png'*/
import SearchBar from '../shared/SearchBar';
import ServiceList from '../services/ServiceList';
import FeaturedTourList from '../components/Featured-tour/FeaturedTourList';
import experinceImg from '../assets/images/experience.png'
import MasonryImagesGallery from '../components/Image-gallery/MasonryImagesGallery';
import Testimonals from '../components/Testimonals/Testimonals';
import Newsletter from '../shared/Newsletter';
import Subtitle from '../shared/Subtitle';
import VideoCarousel from '../components/Carousel/VideoCarousel';


const Home = () => {
  return (
   <>
   {/*=================== hero section start ==============*/}
   <section>
   <Container>
     
<Row>
      <VideoCarousel/>
        <SearchBar/>
        </Row>
    </Container>
   </section>
   {/*=================== hero section ends ==============*/} 
   <section>
    <Container>
      <Row>
        <Col lg='3'>
          <h5 className='services__subtitle'>What we serve</h5>
           <h2 className='services__title'>We offer our best services</h2>       
        </Col>
        <ServiceList/>
      </Row>
    </Container>
   </section>
   <section>
    <Container>
      <Row>
        <Col lg='12' className='mb-5'>
        <Subtitle subtitle={'Explore'}/>
        <h2 className='featured__tour-title'>Our featured tours</h2>

        </Col>
        <FeaturedTourList/>
      </Row>
    </Container>
   </section>
   {/* ====================featured tour end ============*/}
   {/* =================experince section start==========*/}
   <section>
    <Container>
      <Row>
        <Col lg='6'>
           <div className='experience__content'>
            <Subtitle subtitle={'Experience'}/>
            <h2>With our all experince <br/> we will serve you </h2>
            <p>Everything that will ever be needed to make your trip memeorable 
            <br/>
            Full yime servive availbe at any time at your own convence</p>
           </div>
           <div className='counter__wrapper d-flex align-items-center gap-5'>
          <div className='counter__box'>
            <span>1k+</span>
            <h6>Successful Trip</h6>
          </div>
          <div className='counter__box'>
            <span>12k+</span>
            <h6>Regular Clients</h6>
          </div>
          <div className='counter__box'>
            <span>5</span>
            <h6>Years experience</h6>
          </div>

           </div>
        </Col>
        <Col lg='6'>
           <div className='experience__img'>
            <img src={experinceImg} alt=''/>
           </div>
        </Col>
      </Row>
    </Container>
   </section>
    {/* =================experince section end==========*/}
  {/*===================================gallery section start===========*/}
  <section id="gallery">
    <Container>
      <Row>
        <Col lg='12'>
          <Subtitle subtitle={'Gallery'}/>
          <h2 className='gallery__title'>Visit our customers tour gallery </h2>
        </Col>
        <Col lg='12'>
       
          <MasonryImagesGallery/>
     
        </Col>
      </Row>
    </Container>
  </section>
    {/*===================================gallery section end=====================*/}
  <section>
    <Container>
      <Row>
        <Col lg='12'>
          <Subtitle subtitle={'Fans Love'}/>
          <h2 className='testimonal__title'>What our fans say about us</h2>
        </Col>
        <Col lg='12'>
           <Testimonals/>
        </Col>
      </Row>
    </Container>
  </section>
  <Newsletter/>
   </>
  )
}

export default Home
