import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../styles/about.css';

const About = () => {
  return (
    <div className='about-page'>
      <section>
        <Container>
          <Row>
            <h1>Unlocking the Significance of "Ghume Ghume": A Deep Dive into Its Meaning and Usage</h1>
            <p>Welcome back to my vlog, everyone! Today, we're exploring the captivating term "Ghume Ghume," a phrase that's been weaving its way through Bollywood songs, everyday conversations, and social media hashtags. But what does it really signify, and how did it come to be? Join me as we unravel the mysteries of this linguistic gem.</p>

            <p>The term "Ghume Ghume" finds its roots in Hindi, where "Ghume" means "to roam" or "to wander." The repetition of the word not only emphasizes the action but also adds a playful, carefree twist, akin to saying "roaming around and around" with a sense of joyful abandon.</p>

            <p>From Bollywood soundtracks that set the stage for scenes of idyllic escapades to casual conversations amongst friends planning their next adventure, "Ghume Ghume" captures the essence of joy, freedom, and romantic whimsy. It's a term that celebrates the act of exploration, urging us to welcome life's serendipitous moments with open arms.</p>

            <p>Beyond its literal usage, "Ghume Ghume" symbolizes a broader ethos: a celebration of freedom, an appreciation for the unexpected, and an invitation to live spontaneously. In our fast-paced world, finding moments to "Ghume Ghume" can be a soul-soothing escape, reconnecting us with the joys of discovery and the beauty of the journey.</p>

            <p>So, the next time the wanderlust bug bites, remember to let "Ghume Ghume" guide your steps. Whether it's a solo adventure, a journey with loved ones, or just a moment of musical escapism, embrace the wander and the wonder of it all.</p>

            <p>Until we meet again, keep wandering, exploring, and infusing your life with the spirit of "Ghume Ghume." Here's to the journeys that await!</p>
          </Row>
          <Row className='policy-section'>
            <Col xs="12">
              <h3>Cancellation Policy</h3>
              <Row>
                <Col>Cancellation more than 15 days prior</Col>
                <Col>15% deduction (incl. GST)</Col>
              </Row>
              <Row>
                <Col>Cancellation 14 to 8 days prior</Col>
                <Col>35% deduction (incl. GST)</Col>
              </Row>
              <Row>
                <Col>Cancellation 7 to 3 days prior</Col>
                <Col>55% deduction (incl. GST)</Col>
              </Row>
              <Row>
                <Col>Cancellation less than 2 days prior</Col>
                <Col>100% deduction</Col>
              </Row>
            </Col>
          </Row>
          <Row className='terms-section'>
            <Col xs="12">
              <h3>Terms and Conditions</h3>
              <p>Full payment is required in advance for reservations. Room tariffs are inclusive of GST—12% for rooms priced between Rs 1,001 to 7,500 and 18% for rooms priced above Rs 7,500. Rates are subject to applicable laws and regulations.</p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}

export default About;