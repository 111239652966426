import React from 'react'
import './footer.css'
import { Container,Row,Col,ListGroup,ListGroupItem } from 'reactstrap'
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
const quick_links=[
  {
    path:'/home',
    display:'Home'
  },
  {
    path:'/about',
    display:'About'
  },
  {
    path:'/tours',
    display:'Tour'
  },
];
const quick_links2=[
  {
    path:'/#gallery',
    display:'Gallery'
  },
  {
    path:'/login',
    display:'Login'
  },
  {
    path:'/register',
    display:'Register'
  },
];

const Footer = () => {
  const year = new Date().getFullYear();
  const email = "ghumeghume@proton.me";
  const subject = "Interested in your travel services";
  const body = "Hello, I found your contact information on the website and would like to know more about your services. Please get back to me at your earliest convenience.";

  return (
    <footer className='footer'>
      <Container>
        <Row>
          <Col lg='3'>
            <div className='logo'>
              <img src={logo} alt=''/>
              <p>Improve your travel experience take a guide and have heavy discounts with us.</p>
             
              <div className='social__links d-flex align-items-center gap-4'>
  <span>
    <a href="https://www.youtube.com/@GhumeGhume" target="_blank" rel="noopener noreferrer">
      <i className="ri-youtube-line"></i>
    </a>
  </span>
  <span>
    <a href="https://twitter.com/ghumeghume" target="_blank" rel="noopener noreferrer">
      <i className="ri-twitter-line"></i>
    </a>
  </span>
  <span>
    <a href="https://www.facebook.com/ghumeghume" target="_blank" rel="noopener noreferrer">
      <i className="ri-facebook-circle-line"></i>
    </a>
  </span>
  <span>
    <a href="https://www.instagram.com/ghume__ghume/" target="_blank" rel="noopener noreferrer">
      <i className="ri-instagram-line"></i>
    </a>
  </span>
</div>
            </div>
          </Col>
          <Col lg='3'>
            <h5 className='footer__link-title'>Discover</h5>
            <ListGroup className='footer__quick-links'>
              {quick_links.map((item, index) => (
                <ListGroupItem key={index} className='ps-0 border-0'>
                  <Link to={item.path}>{item.display}</Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col lg='3'>
            <h5 className='footer__link-title'>Quick Links</h5>
            <ListGroup className='footer__quick-links'>
              {quick_links2.map((item, index) => (
                <ListGroupItem key={index} className='ps-0 border-0'>
                  <Link to={item.path}>{item.display}</Link>
                </ListGroupItem>
              ))}
            </ListGroup>
          </Col>
          <Col lg='3'>
            <h5 className='footer__link-title'>Contact</h5>
            <ListGroup className='footer__quick-links'>
              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3'>
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span>
                    <i className="ri-map-pin-line"></i>
                  </span>
                  Address:
                </h6>
                <p className='mb-0'>
                  Dharampur, Dehradun
                </p>
              </ListGroupItem>
              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3 '>
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span>
                    <i className="ri-mail-line"></i>
                  </span>
                  Email:
                </h6>
                <a href={`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} className='mb-0'>
                  ghumeghume@proton.me
                </a>
              </ListGroupItem>
              <ListGroupItem className='ps-0 border-0 d-flex align-items-center gap-3'>
                <h6 className='mb-0 d-flex align-items-center gap-2'>
                  <span>
                    <i className="ri-phone-line"></i>
                  </span>
                  Contact Us:
                </h6>
                <p class="button-wrapper">
                <a href={`mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} className="email-button">
                  Click Here
                </a>
   
  
</p>
              </ListGroupItem>
            </ListGroup>
          </Col>
          <Col lg='12' className='text-center pt-5'>
            <p className='copyright'>Copyright {year}, designed and developed by 
            Anushka Uniyal, All rights reserved.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
