import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import heroVid from '../../assets/images/hero-video01.mp4';
import heroVid02 from '../../assets/images/hero-video02.mp4';
import heroVid03 from '../../assets/images/hero-video03.mp4';
import heroVid04 from '../../assets/images/hero-video04.mp4';
import heroVid05 from '../../assets/images/hero-video05.mp4';
import heroVid06 from '../../assets/images/hero-video06.mp4';
import heroVid07 from '../../assets/images/hero-video07.mp4';
import ReactPlayer from 'react-player';
import 'bootstrap/dist/css/bootstrap.css';
import './VideoCarousel.css'; // Ensure your CSS is correctly imported

const VideoCarousel = () => {
  const [index, setIndex] = useState(0);
  const videoProperties = [
    {
      id: 1,
      title: "Nanital",
      src: heroVid,
      credit: "City of Lakes",
    },
    {
      id: 2,
      title: "Haridwar",
      src: heroVid02,
      credit: "HariDashan HarkiPauri",
    },
    {
        id: 3,
        title: "Kedar Valley",
        src: heroVid03,
        credit: "Kedarnath Yatra",
      },
      {
        id: 4,
        title: "Srinagar",
        src: heroVid04,
        credit: "In the bank of Alaknanda River",
      },
      {
        id: 5,
        title: "Rishikesh River Rafting",
        src: heroVid05,
        credit: "An experience of a lifetime! ",
      },
      {
        id: 6,
        title: "Chopta",
        src: heroVid06,
        credit: "A way to Tungnath, Shiva's highest temple",
      },
      {
        id: 7,
        title: "Landour, Mussoorie",
        src: heroVid07,
        credit: "If Mussoorie is the queen of hills, then Landour must be the fairer princess",
      },
  ];
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const handleVideoEnd = () => {
    const nextIndex = index + 1 < videoProperties.length ? index + 1 : 0;
    setIndex(nextIndex);
  };

  return (
    <div className="App">
      <Carousel controls={true} onSelect={handleSelect} indicators={true} pause={false}
   keyboardControls={true}
  >
        {videoProperties.map((videoObj) => (
          <Carousel.Item key={videoObj.id}>
            <ReactPlayer
              url={videoObj.src}
              pip={false}
              controls={true}
              playing={true}
              loop={true}
              width="100%"
              height="100%"
              autoplay={true}
              muted={true}
             
              onEnded={handleVideoEnd}
            />
            <Carousel.Caption>
              <h1>{videoObj.title}</h1>
              <p>{videoObj.credit}</p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default VideoCarousel;
