import React from 'react'
import ServiceCard from './ServiceCard'
import {Col} from 'reactstrap'
import weatherImg from '../assets/images/weather.png'
import guideImg from '../assets/images/guide.png'
import customizationImg from '../assets/images/customization.png'
const servicesData=[
    {
        imgUrl : weatherImg,
        title: "Calculate Weather",
        desc:"Good weather sets a mood to travel, Come along !!!",
    },
    {
        imgUrl:guideImg,
        title:"Best Tour Guide",
        desc:"Have someone to tell you where to go whats the hidden places you will surely enjoy",
    },
    {
        imgUrl:customizationImg,
        title:"Customization",
        desc:"Customize your own trip and make your voice heard ....",
    },
]

const ServiceList = () => {
  return (
   <>
    {
        servicesData.map((item,index) => <Col lg='3' md='6' sm='12' className='mb-4' key={index}>
        <ServiceCard item={item}/>

        </Col>)
    }
   </>
  )
}

export default ServiceList
