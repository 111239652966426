import React from 'react';
import Slider from 'react-slick';
import ava01 from '../../assets/images/ava-1.jpg';
import ava02 from '../../assets/images/ava-2.jpg';
import ava03 from '../../assets/images/ava-3.jpg';
const Testimonals = () => {
    const settings={
       dots:true,
        infinite:true,
        autoplay:true,
        speed:1000,
        swipeToSlide:true,
        autoplaySpeed:2000,
        slidesToShow:3,
        responsive:[
            {
               breakpoint:992,
               settings:{
                slidesToShow:2,
                slidesToScroll:1,
                infinite:true,
                dots:true,
               } ,
            },
            {
                breakpoint:576,
                settings:{
                 slidesToShow:1,
                 slidesToScroll:1,
                
                } ,
             },
        ]
    };
  return (
   <Slider {...settings}>
    <div className='testimonial py-4 px-3'>
<p>Ghumi Ghumi turned our vacation dreams into reality. From flawless logistics to personalized experiences, their team went above and beyond to create a trip tailored to our desires. Their genuine care and attention to detail made every moment unforgettable. Highly recommend for an unforgettable travel experience!</p>
    <div className='d-flex align-items-center gap-4 mt-3'>
        <img src={ava01} className='w-25 h-25 rounded-2' alt=''/>
    <div>
        <h6 className='mb-0 mt-3'>Ayush</h6>
        <p>Customer</p>
    </div>
    
    
    </div>
    
    </div>
    <div className='testimonial py-4 px-3'>
<p>Choosing Ghumi Ghumi for our trip was the best decision we made. They curated a seamless journey filled with adventure, relaxation, and cultural immersion. Every aspect of our trip was meticulously planned, allowing us to fully embrace each destination. Hurray Ghume Ghume!</p>
    <div className='d-flex align-items-center gap-4 mt-3'>
        <img src={ava02} className='w-25 h-25 rounded-2' alt=''/>
    <div>
        <h6 className='mb-0 mt-3'>Palak</h6>
        <p>Customer</p>
    </div>
    
    
    </div>
    
    </div>
    <div className='testimonial py-4 px-3'>
<p>Embarking on our journey with Ghumi Ghumi was an unforgettable experience. From the moment we contacted them, their team exhibited unparalleled professionalism. With their expertise, we explored hidden gems, indulged in local cuisine, and created memories that will last a lifetime.

</p>
    <div className='d-flex align-items-center gap-4 mt-3'>
        <img src={ava03} className='w-25 h-25 rounded-2' alt=''/>
    <div>
        <h6 className='mb-0 mt-3'>Aryan</h6>
        <p>Customer</p>
    </div>
    
    
    </div>
    
    </div>

   </Slider>
  );
};

export default Testimonals;
